import React, { useState } from "react";
import { useSelector } from "react-redux";
import { CallManagerPro } from "../redux/types";
import Row from "./TableRow";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Toolbar,
  Typography,
  Paper,
} from "@material-ui/core";

const hiddenStyle: React.CSSProperties = {
  border: 0,
  clip: "rect(0 0 0 0)",
  height: 1,
  margin: -1,
  overflow: "hidden",
  padding: 0,
  position: "absolute",
  top: 20,
  width: 1,
};

type Order = "asc" | "desc";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T): number {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: any }, b: { [key in Key]: any }) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  id: keyof CallManagerPro;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: "friendlyName", numeric: false, label: "Machine Name" },
  { id: "macAddress", numeric: false, label: "Hardware ID (MAC Address)" },
  { id: "status", numeric: false, label: "Current Status" },
  { id: "allocatedShow", numeric: false, label: "Allocated Production" },
  { id: "allocatedEndpoint", numeric: false, label: "Connected Control Panel" },
];

const CallManagerProTable: React.FC = () => {
  const cmpList = useSelector((state: any) => state.cmpList) as {
    [macAddress: string]: CallManagerPro;
  };
  const customerName = useSelector((state: any) => state.currentCustomer);
  const [searchTerm, setSearchTerm] = useState<string>(undefined);
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof CallManagerPro>("friendlyName");
  const [page, setPage] = useState<number>(0);

  const rowsPerPageOptions = [15, 25, 35];
  const [rowsPerPage, setRowsPerPage] = useState<number>(
    rowsPerPageOptions[rowsPerPageOptions.length - 1]
  );

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof CallManagerPro
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSearchTermChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const cmpListArray = Object.values(cmpList)
    .filter((cmp) => {
      if (searchTerm) {
        const strippedSearchTerm = searchTerm.trim().toLowerCase();
        const strippedFriendlyName = cmp.friendlyName.trim().toLowerCase();
        return strippedFriendlyName.includes(strippedSearchTerm);
      }

      return true;
    })
    .map((cmp) => ({
      ...cmp,
      allocatedShow: cmp.allocatedShow || "",
      allocatedEndpoint: cmp.allocatedEndpoint || "",
      status: getStatus(cmp),
    }));

  return (
    <>
      <Typography component="div" gutterBottom variant="h6">
        Call Manager Pros controlled by {customerName}
      </Typography>
      <hr />
      <Paper>
        <Toolbar>
          <TextField
            label="Search by machine name"
            value={searchTerm}
            onChange={handleSearchTermChange}
          />
        </Toolbar>
        <TableContainer>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                {headCells?.map((headCell) => (
                  <TableCell
                    align="right"
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={(e) => handleRequestSort(e, headCell.id)}
                    >
                      {headCell.label}
                      {orderBy === headCell.id ? (
                        <span style={hiddenStyle}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell align="right">Controls</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stableSort(cmpListArray, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row) => (
                  <Row key={row.friendlyName} row={row} />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={cmpListArray.length}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
};

function getStatus(cmp: CallManagerPro): string {
  if (cmp.status) {
    if (cmp.status["App"]) {
      return cmp.status["App"] + " Call:\n" + cmp.status["CallHandle"];
    } else {
      return cmp.status;
    }
  } else if (cmp.status === false) {
    return "Connected";
  } else if (cmp.status === undefined) {
    return "Disconnected";
  }
}

export default CallManagerProTable;
