import React, { Component } from "react";
import { withAuth } from "@okta/okta-react";
import {
  Button,
  Navbar,
  Popover,
  OverlayTrigger,
  NavItem,
} from "react-bootstrap";
import { checkAuthentication, getUserInfo } from "../../utilities/okta";
import EditOrRegisterCMP from "../EditOrRegisterCMPModal";

import { IconButton } from "@material-ui/core";
import { AccountCircle, AddCircleOutlineOutlined } from "@material-ui/icons";

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      authenticated: null,
      userInfo: null,
      show: false,
    };

    this.checkAuthentication = checkAuthentication.bind(this);
    this.getUserInfo = getUserInfo.bind(this);
  }

  componentDidMount = async () => {
    await this.checkAuthentication();
  };

  componentDidUpdate = async () => {
    await this.checkAuthentication();
  };

  handleLogout = async () => {
    await this.props.auth.logout("/");
  };

  handleOpen = () => {
    this.setState({ show: true });
  };

  handleClose = (data) => {
    this.setState({ show: false });
    this.props.socket.emitRegisterCMP(data);
  };

  goBack = () => {
    this.props.sendCustomerDeselectedEmit();
    this.props.goBack();
  };

  renderUserMenuButton = () => {
    const { userInfo } = this.props;

    const popover = (
      <Popover id="user-popover">
        <Popover.Title as="h3">
          <strong>{userInfo ? userInfo.displayName : ""}</strong>
        </Popover.Title>
        <Popover.Content>
          <Button variant="danger" onClick={this.handleLogout}>
            Logout
          </Button>
        </Popover.Content>
      </Popover>
    );

    const UserButton = () => (
      <OverlayTrigger
        overlay={popover}
        placement="bottom"
        rootClose={true}
        transition={false}
        trigger="click"
      >
        <IconButton style={{ color: "white" }}>
          <AccountCircle />
        </IconButton>
      </OverlayTrigger>
    );

    return <UserButton />;
  };

  render() {
    const { authenticated } = this.state;
    const { currentCustomer, userInfo } = this.props;

    return (
      <React.Fragment>
        <Navbar bg="dark" id="header" variant="dark">
          <Navbar.Brand>AirFirst™ CMP Admin</Navbar.Brand>
          <Navbar.Toggle />
          <NavItem className="justify-content-start">
            {authenticated && userInfo && currentCustomer && (
              <IconButton style={{ color: "white" }} onClick={this.handleOpen}>
                <AddCircleOutlineOutlined />
              </IconButton>
            )}
          </NavItem>
          <Navbar.Collapse className="justify-content-end">
            {currentCustomer && (
              <Button
                id="goBack"
                ref={(ref) => (this.goBackRef = ref)}
                variant="danger"
                onClick={() => {
                  this.goBack();
                }}
              >
                Back
              </Button>
            )}
            {authenticated && userInfo && this.renderUserMenuButton()}
          </Navbar.Collapse>
        </Navbar>
        {this.state.show && (
          <EditOrRegisterCMP
            currentCustomer={currentCustomer}
            handleClose={this.handleClose}
            show={this.state.show}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withAuth(Header);
