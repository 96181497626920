export const DELETE_CMP_SUCCESS = "DELETE_CMP_SUCCESS";
export const DELETE_CMP_FAILURE = "DELETE_CMP_FAILURE";
export const SET_AUTH = "SET_AUTH";

export type EavesDropperType = "participant" | "program";

export interface AuthData {
  accessToken: string;
  userInfo: any;
}

export interface EavesDropper {
  ip: string;
  isAutoRecording: boolean;
  isConnected: boolean;
  status: string;
  systemName: string;
  timeStarted?: string;
  type: EavesDropperType;
  uploadError?: string;
}

export interface CallManagerPro {
  allocated: boolean;
  allocatedEndpoint?: string;
  allocatedShow?: string;
  apps: string[];
  bgColor: string;
  eavesDropParticipantIP?: string;
  eavesDropProgramIP?: string;
  expected: boolean;
  friendlyName: string;
  heloParticipantPassword?: string;
  heloProgramPassword?: string;
  inCall: boolean;
  isAwake: boolean;
  isConnected: boolean;
  isUpdatingSession: boolean;
  lowerThird: any;
  macAddress: string;
  network: string;
  participantEavesDropper?: EavesDropper;
  programEavesDropper?: EavesDropper;
  preferredWebBrowser?: string;
  restartDateTime?: string;
  status?: any;
}

export interface CMPAdminState {
  auth?: AuthData;
  currentCustomer?: string;
  customerList: { [id: string]: string };
  network: string;
  cmpList: { [id: string]: CallManagerPro };
  serverOffsetTime?: any;
  userInfo?: any;
  hostedNetworks: string[];
}

export interface DeleteCMPSuccessAction {
  type: typeof DELETE_CMP_SUCCESS;
  payload: string;
}

export interface DeleteCMPFailureAction {
  type: typeof DELETE_CMP_FAILURE;
  payload: string;
}

export interface SetAuthAction {
  type: typeof SET_AUTH;
  payload: AuthData;
}

export type CMPAdminActionTypes =
  | DeleteCMPSuccessAction
  | DeleteCMPFailureAction
  | SetAuthAction;
