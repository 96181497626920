import { store } from "../redux/reducers";
import { SocketService } from "./socketService";

export const getEventListeners = (socketService: SocketService) => ({
  changeCMPStatus: (params) => {
    store.dispatch({ type: "SET_CMP_LIST", payload: params });
  },
  connect: () => {
    socketService.emitGetCMPList(store.getState().currentCustomer);

    socketService.cmpMainPage?.cmpConnected();
  },
  disconnect: () => {
    socketService.cmpMainPage?.cmpDisconnected();
  },
  hostedNetworks: (params) => {
    store.dispatch({ type: "SET_HOSTED_NETWORKS", payload: params });
  },
  Initial: (params) => {
    const clientTime = new Date().getTime();
    const offsetTime = clientTime - params.currentServerTime;

    store.dispatch({ type: "SET_SERVER_OFFSET_TIME", payload: offsetTime });
    store.dispatch({ type: "SET_CMP_LIST", payload: params.cmpData });
  },
  showList: (params) => {
    store.dispatch({ type: "SET_SHOW_LIST", payload: params });
  },

  updatedCMPStatus: (params) => {
    store.dispatch({ type: "UPDATED_CMP_STATUS", payload: params });
  },
});
