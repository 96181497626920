import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import _ from "lodash";
import utilityFunctions from "../utilities/utilityFunctions.js";

class SelectCustomer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customerList: props.customerList,
      userInfo: props.userInfo,
      hostedNetworks: props.hostedNetworks,
    };
    this.createCustomerSelectionArray = this.createCustomerSelectionArray.bind(
      this
    );
  }

  componentDidUpdate(prevProps) {
    utilityFunctions.compareNewPropsBasedOnState(
      this.state,
      prevProps,
      this.props,
      (_updatedState) => {
        this.setState(_updatedState);
      }
    );
  }

  createCustomerSelectionArray = (_customerList, _hostedNetworks) => {
    if (_customerList && _hostedNetworks) {
      const combinedNetworks = _.union(_customerList, _hostedNetworks);

      let options = [];

      for (var i = 0; i < combinedNetworks.length; i++) {
        const customerName = combinedNetworks[i];
        const shouldDisable =
          !_hostedNetworks.includes(customerName) ||
          !_customerList.includes(customerName);

        options.push(
          <Button
            className={`${shouldDisable ? "disabledStyle" : "enabledStyle"}`}
            disabled={shouldDisable}
            key={i}
            value={customerName}
            onClick={() => {
              this.props.parentFuncs.customerSelected(customerName);
            }}
          >
            {customerName}
          </Button>
        );
      }

      return options;
    } else {
      return (
        <div className="text-left">
          <span>
            No Clients Found.
            <br />
            <br />
            If you would like to upgrade your access, please contact your VCC
            business point of contact.
            <br />
            <br />
            If you believe you have received this message in error, please
            contact{" "}
            <a href="mailto:software@thevcc.tv">
              VCC tech support (software@thevcc.tv)
            </a>
            .
          </span>
        </div>
      );
    }
  };

  render() {
    let { userInfo } = this.state;
    return (
      <>
        {userInfo &&
          userInfo.groups &&
          userInfo.groups.indexOf("Role_QueueTech") > -1 && (
            <div>
              <Row>
                <Col className="text-left" xs={12}>
                  <h2>Client Selection</h2>
                  <hr />
                </Col>
              </Row>
              <Row>
                <Col className="text-center">
                  {this.createCustomerSelectionArray(
                    this.state.customerList,
                    this.state.hostedNetworks
                  )}
                </Col>
              </Row>
            </div>
          )}
        {!(
          userInfo &&
          userInfo.groups &&
          userInfo.groups.indexOf("Role_QueueTech") > -1
        ) && (
          <div>
            <Row
              className="justify-content-start"
              style={{ paddingBottom: "1em" }}
            >
              <h2>
                <strong>Feature Locked</strong>
              </h2>
            </Row>
            <Row className="text-left">
              Your account settings prevent you from accessing this feature. If
              you would like to upgrade your access, please contact your VCC
              business point of contact.
              <br />
              <br />
              <span>
                If you believe you have received this message in error, please
                contact{" "}
                <a href="mailto:software@thevcc.tv">
                  VCC tech support (software@thevcc.tv)
                </a>
                .
              </span>
            </Row>
          </div>
        )}
      </>
    );
  }
}

export default SelectCustomer;
