import React, { useState } from "react";
import TooltipButton from "./TooltipButton";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";

type Action = {
  label: string;
  onClick: any;
  isDisabled?: boolean;
};

type Props = {
  actions: Action[];
  content: React.ReactNode | string;
  dialogTitle: string;
  dialogText?: string;
  isDisabled?: boolean;
  placement?: "top" | "bottom" | "left" | "right";
  size?: "small" | "medium";
  title: string;
};

const AlertDialogButton: React.FC<Props> = (props) => {
  const { actions, children, dialogTitle, dialogText, ...rest } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClickOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <React.Fragment>
      <TooltipButton {...rest} onClick={handleClickOpen} />
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h5">{dialogTitle}</Typography>
        </DialogTitle>
        <DialogContent>
          {children}
          <DialogContentText id="alert-dialog-description">
            {dialogText ?? ""}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button key="cancel" onClick={handleClose}>
            Cancel
          </Button>
          {actions.map((action) => (
            <Button
              disabled={action.isDisabled ?? false}
              key={action.label}
              onClick={() => {
                action.onClick();
                handleClose();
              }}
            >
              {action.label}
            </Button>
          ))}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default AlertDialogButton;
