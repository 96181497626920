/* eslint-disable */
const utilityFunctions = {
  compareNewPropsBasedOnState: (
    _state,
    _prevProps,
    _currentProps,
    _callback
  ) => {
    /* If a component's properties are updated, those properties are checked against the component's state.
    If the state needs to update, this will cause the component to properly re-render!
    This solves SO MUCH BS.*/
    let stateNeedsUpdate = false;

    if (_state) {
      if (_state.name) {
        console.log(`Checking if ${_state.name} needs to update`);
      }
      let statePropertiesToUpdate = {};

      for (var key in _state) {
        if (
          JSON.stringify(_prevProps[key]) !== JSON.stringify(_currentProps[key])
        ) {
          statePropertiesToUpdate[key] = _currentProps[key];
          stateNeedsUpdate = true;
        }
      }

      if (stateNeedsUpdate) {
        _callback(statePropertiesToUpdate);
      }
    }
  },
};

export default utilityFunctions;
