import { useEffect, useState } from "react";
import moment, { Duration } from "moment";

interface UseTimerReturnType {
  timeElapsed?: Duration;
  timeElapsedAsString?: string;
}

export const useTimer = (
  timeStarted: string,
  unitToIncrement: "second" | "minute" | "hour",
  timeOffset?: string
): UseTimerReturnType => {
  const [timeElapsed, setTimeElapsed] = useState<Duration>(undefined);
  const [timeElapsedAsString, setTimeElapsedAsString] = useState<string>();

  const unitDefinitions = {
    second: { ms: 1000, format: "HH:mm:ss" },
    minute: { ms: 60000, format: "HH:mm" },
    hour: { ms: 3600000, format: "HH" },
  };

  const { format } = unitDefinitions[unitToIncrement];
  const convertDurationToString = (duration: Duration) =>
    moment(duration.asMilliseconds()).utc().format(format);

  useEffect(() => {
    if (timeStarted) {
      const momentStartingTime = moment(timeStarted).utc();
      const momentNow = timeOffset
        ? moment(new Date()).utc().add(timeOffset.toString(), "milliseconds")
        : moment(new Date()).utc();

      setTimeElapsed(() => {
        const updatedTimeElapsed = moment.duration(
          momentNow.diff(momentStartingTime)
        );
        setTimeElapsedAsString(
          updatedTimeElapsed
            ? convertDurationToString(updatedTimeElapsed)
            : undefined
        );
        return updatedTimeElapsed;
      });
    } else {
      setTimeElapsed(undefined);
    }
  }, [timeStarted, timeOffset]);

  useEffect(() => {
    if (timeElapsed && unitToIncrement) {
      const { ms: timeoutMilliseconds } = unitDefinitions[unitToIncrement];
      const interval = setInterval(() => {
        setTimeElapsed((timeElapsed) => {
          const updatedTimeElapsed = timeElapsed.add(1, unitToIncrement);
          setTimeElapsedAsString(
            updatedTimeElapsed
              ? convertDurationToString(updatedTimeElapsed)
              : undefined
          );
          return updatedTimeElapsed;
        });
      }, timeoutMilliseconds);

      return () => clearInterval(interval);
    }

    return () => {};
  }, [timeElapsed, unitToIncrement, unitDefinitions]);

  return { timeElapsed, timeElapsedAsString };
};
