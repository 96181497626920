import { connect } from "react-redux";
import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Security, ImplicitCallback, SecureRoute } from "@okta/okta-react";

import EventListener from "react-event-listener";
import MainPage from "./components/MainPage.js";
import LoginPage from "./components/LoginPage";
import "./css/App.css";

import { config } from "./utilities/okta";

const customAuthHandler = ({ history }) => {
  history.push("/login");
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.handleKeyPress = this.handleKeyPress.bind(this);

    this.childFuncs = {
      logOut: () => {
        this.props.dispatch({ type: "LOGOUT", data: {} });
      },
      selectCustomer: (currentCustomerName) => {
        this.props.dispatch({
          type: "CURRENT_CUSTOMER",
          data: currentCustomerName,
        });
      },
      selectNetwork: (network) => {
        this.props.dispatch({ type: "SELECT_NETWORK", data: network });
      },
      getCMPList: (cmpList) => {
        this.props.dispatch({ type: "LIST_CALLERS", data: cmpList });
      },
      setUserInfo: (userInfo) => {
        this.props.dispatch({ type: "SET_USER_INFO", data: { userInfo } });
      },
    };
  }

  handleKeyPress(e) {
    if (e.key === "Escape") {
      let btn = document.getElementById("goBack");
      if (btn) {
        btn.click();
      }
    } else if (e.key === "Enter") {
      let btn = document.getElementById("loginBtn");
      if (btn) {
        btn.click();
      }
    }
  }

  render() {
    return (
      <div className="App">
        <Router>
          <Security {...config.oidc} onAuthRequired={customAuthHandler}>
            <Route component={LoginPage} path="/login" />
            <Route component={ImplicitCallback} path="/implicit/callback" />
            <SecureRoute
              exact
              path="/"
              render={(props) => (
                <MainPage
                  {...props}
                  hostedNetworks={this.props.hostedNetworks}
                  network={this.props.network}
                  parentFuncs={this.childFuncs}
                />
              )}
            />
          </Security>
        </Router>
        <EventListener target="window" onKeyPress={this.handleKeyPress} />
      </div>
    );
  }
}

//Function to map the redux state to object properties
const mapStateToProps = (state, ownProps) => {
  const { currentCustomer, customerList, hostedNetworks, network } = state;

  return {
    currentCustomer,
    customerList,
    hostedNetworks,
    network,
  };
};

//Connect (subscribe) this component to the redux state provider
export default connect(mapStateToProps)(App);
