import { createStore, applyMiddleware } from "redux";
import { CMPAdminState, DELETE_CMP_SUCCESS, SET_AUTH } from "./types";
import thunk from "redux-thunk";
import logger from "redux-logger";

const initialState: CMPAdminState = {
  auth: undefined,
  currentCustomer: undefined,
  customerList: {},
  network: undefined,
  cmpList: {},
  serverOffsetTime: undefined,
  userInfo: undefined,
  hostedNetworks: [],
};

//Set state, with a default state value and a switch that defines how it is modified
const cmpAdminReducer = (state = initialState, action: any): CMPAdminState => {
  const rs = { ...state };

  switch (action.type) {
    case "CURRENT_CUSTOMER":
      if (rs.currentCustomer !== action.data) {
        rs.currentCustomer = action.data;
      }
      return rs;
    case DELETE_CMP_SUCCESS:
      const { [action.payload]: deletedCMP, ...rest } = rs.cmpList;
      return { ...rs, cmpList: rest };
    case "LIST_CUSTOMERS":
      rs.currentCustomer = undefined;
      rs.customerList = action.data;
      return rs;
    case "LOGOUT":
      for (var key in rs) {
        rs[key] = initialState[key];
      }
      return rs;
    case "UPDATED_CMP_STATUS":
      const { cmp } = action.payload;

      rs.cmpList = {
        ...rs.cmpList,
        [cmp.macAddress]: cmp,
      };

      return rs;
    case "SELECT_NETWORK":
      rs.network = action.data;
      return rs;
    case SET_AUTH:
      return { ...rs, auth: action.payload };
    case "SET_CMP_LIST":
      rs.cmpList = action.payload;
      return rs;
    case "SET_HOSTED_NETWORKS":
      const { hostedNetworks } = action.payload;
      rs.hostedNetworks = hostedNetworks;
      return rs;
    case "SET_SERVER_OFFSET_TIME":
      if (!rs.serverOffsetTime) {
        rs.serverOffsetTime = action.payload;
      }
      return rs;
    case "SET_USER_INFO":
      const { userInfo } = action.data;
      rs.userInfo = userInfo;
      return rs;
    default:
      return rs;
  }
};

//Combine all of the reducers, importing them using require
export const store = createStore(
  cmpAdminReducer,
  applyMiddleware(thunk, logger)
);
