import React, { Component } from "react";
import OktaSignIn from "@okta/okta-signin-widget/dist/js/okta-sign-in.min.js";
import "@okta/okta-signin-widget/dist/css/okta-sign-in.min.css";

import { config } from "../utilities/okta";

import Header from "./Header";

import { PUBLIC_URL } from "../config";

export default class LoginPage extends Component {
  constructor(props) {
    super(props);

    const { pkce, issuer, clientId, redirectUri, scopes } = config.oidc;

    this.signIn = new OktaSignIn({
      baseUrl: issuer.split("/oauth2")[0],
      logo: `${PUBLIC_URL}/img/airfirst_logo.png`,
      clientId,
      redirectUri,
      i18n: {
        en: {
          "primaryauth.username.placeholder": "Email",
          "primaryauth.password.placeholder": "Password",
          "errors.E0000004": "The email or password you provided is incorrect.",
        },
      },
      authParams: {
        pkce,
        issuer,
        display: "page",
        scopes,
        responseType: ["code"],
        grantType: "authorization_code",
      },
    });
  }

  componentDidMount() {
    this.signIn.renderEl(
      { el: "#sign-in-widget" },
      () => {},
      (err) => {
        throw err;
      }
    );
  }

  componentWillUnmount() {
    this.signIn.remove();
  }

  render() {
    return (
      <>
        <Header />
        <div>
          <div id="sign-in-widget" />
          <a
            className="oktaLink"
            href="https://www.okta.com/"
            rel="noopener noreferrer"
            target="_blank"
          >
            Login Powered by Okta
          </a>
          <div className={"patentText"}>
            Covered by one or more US and International patents. See{" "}
            <a href="http://www.thevcc.tv/ip/" rel="noreferrer" target="_blank">
              https://www.thevcc.tv/ip/
            </a>
          </div>
        </div>
      </>
    );
  }
}
