import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./redux/reducers";
import { SocketContext, SocketService } from "./utilities/socketService";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/oktaWidget.css";
import "./css/index.css";

const socketService = new SocketService();

ReactDOM.render(
  <Provider store={store}>
    <SocketContext.Provider value={socketService}>
      <App />
    </SocketContext.Provider>
  </Provider>,
  document.getElementById("root")
);
