import axios from "axios";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { SERVER_ROOT } from "../config";
import {
  AuthData,
  CMPAdminActionTypes,
  DELETE_CMP_FAILURE,
  DELETE_CMP_SUCCESS,
  SET_AUTH,
} from "./types";

const axiosInstance = axios.create({
  baseURL: `${SERVER_ROOT}/api/cmpAdmin`,
  params: {
    appName: "cmpAdmin",
  },
  headers: {
    "Content-Type": "application/json",
  },
});

const getAuthHeader = (accessToken: string) => ({
  Authorization: `Bearer ${accessToken}`,
});

export const deleteCMP = (data: {
  id: string;
  macAddress: string;
  network: string;
}): ThunkAction<Promise<void>, {}, {}, CMPAdminActionTypes> => {
  return async (
    dispatch: ThunkDispatch<{}, {}, CMPAdminActionTypes>,
    getState: any
  ) => {
    try {
      const { auth } = getState();
      await axiosInstance.delete(`/cmp`, {
        data,
        headers: {
          ...getAuthHeader(auth?.accessToken ?? ""),
        },
      });

      dispatch(deleteCMPSuccess(data.macAddress));
    } catch (err) {
      dispatch(deleteCMPFailure(err?.response?.data ?? ""));
    }
  };
};

const deleteCMPSuccess = (macAddress: string): CMPAdminActionTypes => ({
  type: DELETE_CMP_SUCCESS,
  payload: macAddress,
});

const deleteCMPFailure = (message: string): CMPAdminActionTypes => ({
  type: DELETE_CMP_FAILURE,
  payload: message,
});

export const setAuth = (auth: AuthData): CMPAdminActionTypes => ({
  type: SET_AUTH,
  payload: auth,
});
