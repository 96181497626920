import React, { useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { SocketContext } from "../utilities/socketService";
import EditOrRegisterCMPModal from "./EditOrRegisterCMPModal";
import { useTimer } from "../utilities/customHooks";
import { CallManagerPro } from "../redux/types";
import {
  Box,
  Collapse,
  IconButton,
  TableCell,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import {
  Autorenew as AutorenewIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  NotInterested as NotInterestedIcon,
  PlayArrow as PlayArrowIcon,
  Settings as SettingsIcon,
  Stop as StopIcon,
  SurroundSound as SurroundSoundIcon,
  SwitchCamera as SwitchCameraIcon,
} from "@material-ui/icons";
import AlertDialogButton from "./AlertDialogButton";
import TooltipButton from "./TooltipButton";
import styles from "./TableRow.module.css";

type Props = {
  row: CallManagerPro;
};

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

const Row: React.FC<Props> = (props) => {
  const { row } = props;
  const { isAwake, participantEavesDropper, programEavesDropper, status } = row;

  const socketService = useContext(SocketContext);
  const canvasRef = useRef<HTMLCanvasElement>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [shouldShowModal, setShouldShowModal] = useState<boolean>(false);
  const customerName = useSelector((state: any) => state.currentCustomer);
  const classes = useRowStyles();

  const { timeElapsedAsString } = useTimer(row.restartDateTime, "second");
  const emitData = { network: row.network, macAddress: row.macAddress };

  useEffect(() => {
    if (canvasRef.current?.getContext) {
      const ctx = canvasRef.current.getContext("2d");
      const statusColorMapping = {
        Restarting: "yellow",
        Disconnected: "red",
        "Unable to Shutdown": "orange",
      };
      const statusColor = statusColorMapping[status];
      ctx.fillStyle = statusColor ?? (isAwake ? "green" : "blueviolet");
      ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
      ctx.fillStyle = "black";
      ctx.textBaseline = "bottom";
    }
  }, [canvasRef, status, isAwake]);

  const handleOpenModal = () => {
    setShouldShowModal(true);
  };

  const handleCloseModal = (data) => {
    if (data) {
      socketService.emitUpdateCMP(data);
      // TODO: This is a KLUDGE.  Remove this and ensure the Queue Server is just sending the correct emit after awaiting the change to the CMP in question
      setTimeout(() => {
        socketService.emitGetCMPList(data.network);
      }, 500);
    }
    setShouldShowModal(false);
  };

  const isRestartingOrOffline =
    status === "Restarting" ||
    status === "Restart Initiated" ||
    row.isConnected === false;
  const isDeallocateButtonDisabled = !row.allocatedEndpoint || row.inCall;
  const isRestartButtonDisabled = isRestartingOrOffline || row.inCall;
  const isActionButtonDisabled =
    isRestartingOrOffline || row.isUpdatingSession || row.inCall;

  let displayStatus = status;
  if (status === "Restarting") displayStatus = timeElapsedAsString;
  else if (status === "Connected" && !row.isUpdatingSession)
    displayStatus = `Connected (${
      isAwake ? "session active" : "session inactive"
    })`;
  else if (status === "Connected" && row.isUpdatingSession)
    displayStatus = "Connected (changing session state)";

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <canvas className={styles.canvas} ref={canvasRef}></canvas>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.friendlyName}
        </TableCell>
        <TableCell align="right">{row.macAddress}</TableCell>
        <TableCell align="right">{displayStatus}</TableCell>
        <TableCell align="right">
          {row.allocatedShow || "Not Allocated"}
        </TableCell>
        <TableCell align="right">
          {row.allocatedEndpoint || "Not Allocated"}
        </TableCell>
        <TableCell align="right">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setIsOpen((isOpen) => !isOpen)}
          >
            {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={6} style={{ paddingBottom: 0, paddingTop: 0 }}>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <TooltipButton
                content={<SettingsIcon />}
                isDisabled={row.inCall}
                title="Settings"
                onClick={handleOpenModal}
              />
              <AlertDialogButton
                actions={[
                  {
                    label: "Confirm",
                    onClick: () =>
                      socketService.emitDeallocateCMP({
                        ...row,
                        id: row.macAddress,
                      }),
                  },
                ]}
                content={<NotInterestedIcon />}
                dialogTitle="Deallocate CMP:"
                isDisabled={isDeallocateButtonDisabled}
                placement="top"
                size="medium"
                title="Deallocate"
              >
                <div className={styles.confirmAlert}>{row.friendlyName}</div>
              </AlertDialogButton>
              <AlertDialogButton
                actions={[
                  {
                    label: "Confirm",
                    onClick: () =>
                      socketService.emitRestartCMP({
                        network: row.network,
                        macAddress: row.macAddress,
                      }),
                  },
                ]}
                content={<AutorenewIcon />}
                dialogTitle="Restart CMP:"
                isDisabled={isRestartButtonDisabled}
                placement="top"
                size="medium"
                title="Restart"
              >
                <div className={styles.confirmAlert}>{row.friendlyName}</div>
              </AlertDialogButton>
              <AlertDialogButton
                actions={[
                  {
                    label: "Confirm",
                    onClick: () =>
                      socketService.emitRestartCoreAudioDaemonCMP({
                        network: row.network,
                        macAddress: row.macAddress,
                      }),
                  },
                ]}
                content={<SurroundSoundIcon />}
                dialogTitle="Reset core audio daemon on CMP:"
                isDisabled={isActionButtonDisabled}
                placement="top"
                size="medium"
                title="Reset Core Audio Daemon"
              >
                <div className={styles.confirmAlert}>{row.friendlyName}</div>
              </AlertDialogButton>
              <AlertDialogButton
                actions={[
                  {
                    label: "Confirm",
                    onClick: () =>
                      socketService.emitWakeCMP({
                        network: row.network,
                        macAddress: row.macAddress,
                      }),
                  },
                ]}
                content={<PlayArrowIcon />}
                dialogTitle="Start session on CMP:"
                isDisabled={isActionButtonDisabled || row.isAwake}
                placement="top"
                size="medium"
                title="Start Session"
              >
                <div className={styles.confirmAlert}>{row.friendlyName}</div>
              </AlertDialogButton>
              <AlertDialogButton
                actions={[
                  {
                    label: "Confirm",
                    onClick: () =>
                      socketService.emitSleepCMP({
                        network: row.network,
                        macAddress: row.macAddress,
                      }),
                  },
                ]}
                content={<StopIcon />}
                dialogTitle="Stop session on CMP:"
                isDisabled={isActionButtonDisabled || !row.isAwake}
                placement="top"
                size="medium"
                title="Stop Session"
              >
                <div className={styles.confirmAlert}>{row.friendlyName}</div>
              </AlertDialogButton>
              <AlertDialogButton
                actions={[
                  {
                    label: "Program",
                    onClick: () =>
                      socketService.emitRebootEavesDropperDevice({
                        ...emitData,
                        type: "program",
                      }),
                    isDisabled: programEavesDropper
                      ? programEavesDropper.isConnected === false
                      : true,
                  },
                  {
                    label: "Participant",
                    onClick: () =>
                      socketService.emitRebootEavesDropperDevice({
                        ...emitData,
                        type: "participant",
                      }),
                    isDisabled: participantEavesDropper
                      ? participantEavesDropper.isConnected === false
                      : true,
                  },
                ]}
                content={<SwitchCameraIcon />}
                dialogTitle={`Reboot EavesDropper device on CMP:`}
                isDisabled={isActionButtonDisabled}
                placement="top"
                size="medium"
                title="Reboot EavesDropper Device"
              >
                <div className={styles.confirmAlert}>{row.friendlyName}</div>
                <div>
                  <ul>
                    <li>
                      Program EavesDropper:{" "}
                      {programEavesDropper
                        ? programEavesDropper.status
                        : "Not Configured"}
                    </li>
                    <li>
                      Participant EavesDropper:{" "}
                      {participantEavesDropper
                        ? participantEavesDropper.status
                        : "Not Configured"}
                    </li>
                  </ul>
                </div>
              </AlertDialogButton>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <EditOrRegisterCMPModal
        cmpInfo={row}
        currentCustomer={customerName}
        handleClose={(data) => {
          handleCloseModal(data);
        }}
        show={shouldShowModal}
      />
    </React.Fragment>
  );
};

export default Row;
