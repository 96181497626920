import { useState } from "react";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { deleteCMP } from "../redux/actions";
import { useDispatch } from "react-redux";
import * as yup from "yup";

import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

// TODO: Get both these options from the server
const appOptions = [
  { value: "Facetime", label: "Facetime" },
  { value: "Gruveo", label: "Gruveo" },
  { value: "Twilio", label: "VCC WebRTC" },
];

const browserOptions = [
  { value: null, label: "No Preference" },
  { value: "Google Chrome", label: "Google Chrome" },
  { value: "Safari", label: "Safari" },
];

const recordingDestinationOptions = [
  { value: "smb", label: "SMB Network Share" },
  { value: "sd", label: "SD Card" },
];

const validationSchema = yup.object().shape({
  network: yup.string().when("isShowingNetwork", {
    is: false,
    then: yup.string().required("Must enter a network."),
  }),
  friendlyAlias: yup.string().trim().required("Must enter a friendly alias."),
  macAddress: yup.string().trim().required("Must enter a MAC address."),
  eavesDropParticipantIP: yup.string().trim(),
  eavesDropProgramIP: yup.string().trim(),
  heloParticipantPassword: yup.string().trim(),
  heloProgramPassword: yup.string().trim(),
  preferredWebBrowser: yup.object(),
  recordingDestination: yup.object(),
  isShowingNetwork: yup.boolean(),
  monitoringFeed: yup.string().trim(),
});

const EditOrRegisterCMPModal = (props) => {
  const dispatch = useDispatch();
  const { handleClose, cmpInfo, show, currentCustomer } = props;
  const { control, errors, handleSubmit, register } = useForm({
    validationSchema,
  });

  // Converts comma seperated string to ex: [{ value: "App", label: "App"}] if apps exist
  let defaultApps = Boolean(cmpInfo) ? [] : appOptions;

  if (Boolean(cmpInfo)) {
    cmpInfo?.apps?.split(",").forEach((app) => {
      defaultApps.push({
        value: app,
        label: app === "Twilio" ? "VCC WebRTC" : app,
      });
    });
  }

  const [apps, setApps] = useState(defaultApps);
  /*const [isL3Compatible, setIsL3Compatible] = useState(
    cmpInfo?.lowerThird || false
  );*/
  const isL3Compatible = false;

  const onSubmit = (data) => {
    // Converts ex: [{value: "App", label: "App"}] to comma seperated string

    const applications = apps?.map((app) => app.value).join(",") ?? "";
    const formattedData = {
      ...data,
      macAddress: cmpInfo?.macAddress ?? data.macAddress,
      network: cmpInfo?.network ?? data.network,
      applications,
      preferredWebBrowser: data.preferredWebBrowser?.value ?? null,
      recordingDestination: data.recordingDestination?.value ?? null,
      //monitoringFeed: data.monitoringFeed?.value ?? null, // this goes null always??
      isL3Compatible,
    };

    handleClose(formattedData);
  };

  function handleDeleteClick() {
    const { id, macAddress, network } = cmpInfo;
    dispatch(deleteCMP({ id, macAddress, network }));
  }

  const defaultPreferredBrowser = cmpInfo?.preferredWebBrowser
    ? browserOptions.find(
        (options) => options.value === cmpInfo.preferredWebBrowser
      )
    : browserOptions[0];

  const defaultRecordingDestination = cmpInfo?.recordingDestination
    ? recordingDestinationOptions.find(
        (options) => options.value === cmpInfo.recordingDestination
      )
    : recordingDestinationOptions[0];

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>
          {cmpInfo
            ? `Editing Info For CMP: ${cmpInfo.friendlyName}`
            : "Register New CMP"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <input
            name="isShowingNetwork"
            ref={register}
            type="hidden"
            value={cmpInfo?.network ? true : false}
          ></input>
          {!cmpInfo?.network && (
            <Form.Group>
              <Form.Label>Network</Form.Label>
              <Form.Control
                defaultValue={currentCustomer}
                isInvalid={errors?.network}
                name="network"
                ref={register}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.network?.message}
              </Form.Control.Feedback>
            </Form.Group>
          )}
          <Form.Group>
            <Form.Label>Machine Name</Form.Label>
            <Form.Control
              defaultValue={cmpInfo?.friendlyName ?? ""}
              isInvalid={errors?.friendlyAlias}
              name="friendlyAlias"
              ref={register}
            />
            <Form.Control.Feedback type="invalid">
              {errors?.friendlyAlias?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>MAC Address</Form.Label>
            {!cmpInfo?.macAddress && (
              <Form.Control
                defaultValue={""}
                isInvalid={errors?.macAddress}
                name="macAddress"
                ref={register}
              />
            )}
            {cmpInfo?.macAddress && (
              <Form.Label>{`: ${cmpInfo?.macAddress}`}</Form.Label>
            )}
            <Form.Control.Feedback type="invalid">
              {errors?.macAddress?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Browser for WebRTC Apps</Form.Label>
            <Controller
              as={Select}
              control={control}
              defaultValue={defaultPreferredBrowser}
              name="preferredWebBrowser"
              options={browserOptions}
              onChange={([selected]) => selected}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Applications</Form.Label>
            <Select
              isMulti
              isSearchable
              name="applications"
              options={appOptions}
              value={apps}
              onChange={(selected) => setApps(selected)}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Monitoring Feed</Form.Label>
            <Form.Control
              defaultValue={cmpInfo?.monitoringFeed || ""}
              name="monitoringFeed"
              ref={register}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Recording Destination</Form.Label>
            <Controller
              as={Select}
              control={control}
              defaultValue={defaultRecordingDestination}
              name="recordingDestination"
              options={recordingDestinationOptions}
              onChange={([selected]) => selected}
            />
          </Form.Group>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>EavesDrop Participant LAN IP</Form.Label>
              <Form.Control
                defaultValue={cmpInfo?.eavesDropParticipantIP || ""}
                name="eavesDropParticipantIP"
                ref={register}
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>EavesDrop Program LAN IP</Form.Label>
              <Form.Control
                defaultValue={cmpInfo?.eavesDropProgramIP || ""}
                name="eavesDropProgramIP"
                ref={register}
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>HELO Participant Password</Form.Label>
              <Form.Control
                defaultValue={cmpInfo?.heloParticipantPassword || ""}
                name="heloParticipantPassword"
                ref={register}
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>HELO Program Password</Form.Label>
              <Form.Control
                defaultValue={cmpInfo?.heloProgramPassword || ""}
                name="heloProgramPassword"
                ref={register}
              />
            </Form.Group>
          </Form.Row>
          {/*<Form.Group>
            <Form.Label>L3 Compatible</Form.Label>
            <br />
            <ButtonGroup>
              <Button
                variant={isL3Compatible ? "success" : "light"}
                onClick={() => setIsL3Compatible(true)}
              >
                Yes
              </Button>
              <Button
                variant={!isL3Compatible ? "success" : "light"}
                onClick={() => setIsL3Compatible(false)}
              >
                No
              </Button>
            </ButtonGroup>
          </Form.Group>*/}
          <hr />
          <Button variant="secondary" onClick={() => handleClose()}>
            Close
          </Button>
          {cmpInfo && (
            <Button
              disabled={cmpInfo.isConnected}
              variant="danger"
              onClick={handleDeleteClick}
            >
              Delete
            </Button>
          )}
          <Button className="float-right" type="submit" variant="primary">
            Save
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default EditOrRegisterCMPModal;
