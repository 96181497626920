import { createContext } from "react";
import { io, Socket } from "socket.io-client";
import { EavesDropperType } from "../redux/types";
import { SERVER_ROOT } from "../config";
import { getEventListeners } from "./eventListeners";

export class SocketService {
  private socket?: Socket;

  public cmpMainPage?: any;

  public init(accessToken: string, mainPage: any): SocketService {
    this.socket = io(`${SERVER_ROOT}/cmpAdmin`, {
      reconnection: true,
      secure: true,
      query: {
        accessToken,
        appName: "cmpAdmin",
      },
    });

    this.attachEventListeners();

    this.cmpMainPage = mainPage;

    return this;
  }

  public disconnect(): void {
    Object.keys(getEventListeners(this)).forEach((event) =>
      this.socket?.off(event)
    );
    this.socket?.disconnect();
    this.socket = undefined;
  }

  private attachEventListeners(): void {
    Object.entries(getEventListeners(this)).forEach(([event, listener]) =>
      this.socket?.on(event, listener)
    );
  }

  emitGetCMPList(_network) {
    this.socket?.emit("selectNetwork", _network);
  }

  emitRestartCMP(_resetInfo) {
    this.socket?.emit("restartCMP", _resetInfo);
  }

  emitDeallocateCMP(_cmpInfo) {
    this.socket?.emit("deallocateCMP", _cmpInfo);
  }

  emitCustomerDeselected() {
    this.socket?.emit("customerDeselected");
  }

  emitRebootEavesDropperDevice(data: {
    network: string;
    macAddress: string;
    type: EavesDropperType;
  }) {
    this.socket?.emit("rebootEavesDropperDevice", data);
  }

  emitRegisterCMP(data) {
    this.socket?.emit("registerCMP", data);
  }

  emitRestartCoreAudioDaemonCMP(data) {
    this.socket?.emit("restartCoreAudioDaemonCMP", data);
  }

  emitSleepCMP(data) {
    this.socket?.emit("sleepCMP", data);
  }

  emitUpdateCMP(data) {
    this.socket?.emit("updateCMP", data);
  }

  emitWakeCMP(data) {
    this.socket?.emit("wakeCMP", data);
  }
}

export const SocketContext = createContext<SocketService>(new SocketService());
