import { OKTA_CLIENT_ID, OKTA_ISSUER } from "../config";

export const config = {
  oidc: {
    clientId: OKTA_CLIENT_ID,
    issuer: OKTA_ISSUER,
    redirectUri: `${window.location.origin}/implicit/callback/`,
    scopes: ["openid", "profile", "email"],
    pkce: true,
  },
};

export async function checkAuthentication() {
  const authenticated = await this.props.auth.isAuthenticated();
  if (authenticated !== this.state.authenticated) {
    if (authenticated && !this.state.userInfo) {
      const userInfo = await this.props.auth.getUser();
      this.setState({ authenticated, userInfo });
    } else {
      this.setState({ authenticated });
    }
  }
}

export async function getUserInfo() {
  const userInfo = await this.props.auth.getUser();
  this.setState({ userInfo });
}
