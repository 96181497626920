import React, { Component } from "react";
import { Container } from "react-bootstrap";
import { withAuth } from "@okta/okta-react";
import { connect } from "react-redux";
import utilityFunctions from "../utilities/utilityFunctions.js";
import { checkAuthentication, getUserInfo } from "../utilities/okta";

import SelectCustomer from "./SelectCustomer.js";
import Header from "./Header";
import CallManagerProTable from "./Table";

import { SocketContext } from "../utilities/socketService";
import { setAuth } from "../redux/actions";

class MainPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authenticated: null,
      currentCustomer: props.currentCustomer,
      currentNotification: false,
      customerList: props.customerList,
      editing: false,
      lastError: false,
      status: "",
      socketDisconnected: false,
      userInfo: null,
      hostedNetworks: props.hostedNetworks,
    };

    // Functions that cannot be passed to children safely (are called by other children)
    this.goBack = this.goBack.bind(this);
    this.checkAuthentication = checkAuthentication.bind(this);
    this.getUserInfo = getUserInfo.bind(this);
    this.serverSocket = null;

    this.childFuncs = {
      general: {
        goBack: (_fromWhere) => {
          this.goBack(_fromWhere);
        },
      },
      selectCustomer: {
        customerSelected: (_customerName) => {
          this.setState(
            {
              currentCustomer: _customerName,
              editing: false,
              searching: true,
              searchString: false,
            },
            () => {
              this.sendCMPListEmit(this.state.currentCustomer);
              this.props.parentFuncs.selectCustomer(this.state.currentCustomer);
            }
          );
        },
      },
    };
  }

  componentDidMount = async () => {
    await this.checkAuthentication();
    await this.getUserInfo();
    this.props.parentFuncs.setUserInfo(this.state.userInfo);

    const accessToken = await this.props.auth.getAccessToken();
    this.props.dispatch(
      setAuth({
        accessToken,
        userInfo: this.state.userInfo,
      })
    );
    //initalize socket connection with access token
    this.serverSocket = this.context.init(accessToken, this);
  };

  componentDidUpdate = async (prevProps) => {
    await this.checkAuthentication();
    // const token = await this.props.auth.getAccessToken();
    // this.serverSocket.updateToken(token);
    //initalize socket connection with access token
    //this.serverSocket = new cmpAdminServerSocket(token);
    if (prevProps.network !== this.props.network) {
      this.goBack("cmpAdminPage");
    }
    utilityFunctions.compareNewPropsBasedOnState(
      this.state,
      prevProps,
      this.props,
      (_updatedState) => {
        this.setState(_updatedState);
      }
    );
  };

  goBack = (_fromWhere) => {
    switch (_fromWhere) {
      case "cmpAdminPage":
        this.props.dispatch({ type: "SET_CMP_LIST", payload: {} });
        this.setState({ currentCustomer: false });
        break;
      default:
        break;
    }
  };

  sendCMPListEmit = (network) => {
    this.serverSocket.emitGetCMPList(network, this);
    return true;
  };

  sendCustomerDeselectedEmit = () => {
    this.serverSocket.emitCustomerDeselected();
  };

  // ===================================================================================================================================
  // GET requests

  cmpDisconnected() {
    this.setState({ socketDisconnected: true });
  }

  cmpConnected() {
    this.setState({ socketDisconnected: false });
  }

  parseCustomerListFromNetworks(userInfo) {
    let customerList = [];
    if (userInfo && userInfo.groups) {
      for (var i = 0; i < userInfo.groups.length; i++) {
        if (userInfo.groups[i].indexOf("Network_") > -1) {
          customerList.push(userInfo.groups[i].replace("Network_", ""));
        }
      }
    }
    return customerList;
  }

  // ===================================================================================================================================
  render() {
    const {
      authenticated,
      currentCustomer,
      socketDisconnected,
      userInfo,
      hostedNetworks,
    } = this.state;

    const { cmpList, serverOffsetTime } = this.props;

    const customerList = this.parseCustomerListFromNetworks(userInfo);

    return (
      <>
        {authenticated && this.serverSocket && (
          <Header
            currentCustomer={currentCustomer}
            goBack={(_fromWhere) => this.goBack("cmpAdminPage")}
            sendCustomerDeselectedEmit={this.sendCustomerDeselectedEmit}
            socket={this.serverSocket}
            userInfo={this.state.userInfo}
          />
        )}
        {socketDisconnected && (
          <div
            style={{
              alignItems: "center",
              backgroundColor: "red",
              color: "white",
              display: "flex",
              fontSize: "1.5vh",
              fontWeight: "bold",
              justifyContent: "center",
              position: "relative",
              width: "100%",
              zIndex: 9999,
            }}
          >
            Server Socket disconnected: Refreshing the page may fix this
          </div>
        )}
        {authenticated && (
          <div
            id="mainForm"
            style={{ display: "flex", height: "50vh !important" }}
          >
            <Container
              style={{
                width: "90%",
                paddingTop: "30px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              {
                /* Select Customer */
                !currentCustomer && (
                  <SelectCustomer
                    customerList={customerList}
                    hostedNetworks={hostedNetworks}
                    parentFuncs={this.childFuncs.selectCustomer}
                    userInfo={this.state.userInfo}
                  />
                )
              }
              {
                /* Select CMP List */
                currentCustomer &&
                  serverOffsetTime &&
                  JSON.stringify(cmpList) !== JSON.stringify({}) && (
                    <CallManagerProTable cmpList={cmpList} />
                  )
              }
              {currentCustomer &&
                serverOffsetTime &&
                JSON.stringify(cmpList) === JSON.stringify({}) && (
                  <h3>No CMP's Available</h3>
                )}
            </Container>
          </div>
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return { dispatch };
};

const mapStateToProps = (state) => {
  const { cmpList, serverOffsetTime } = state;

  return {
    cmpList,
    serverOffsetTime,
  };
};

MainPage.contextType = SocketContext;

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(MainPage));
