import React from "react";
import { IconButton, Tooltip } from "@material-ui/core";

type Props = {
  content: React.ReactNode | string;
  isDisabled?: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  placement?: "top" | "bottom" | "left" | "right";
  size?: "small" | "medium";
  title: string;
};

const TooltipButton: React.FC<Props> = (props) => {
  const {
    content,
    isDisabled = false,
    onClick,
    placement = "top",
    size = "medium",
    title,
  } = props;

  return (
    <Tooltip placement={placement} title={title}>
      <span>
        <IconButton disabled={isDisabled} size={size} onClick={onClick}>
          <span>{content}</span>
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default TooltipButton;
